import React, { useEffect, useState } from 'react';

import { useAuthContext } from '../../../hooks/useAuthContext';

import game7 from "../../../assets/img/games/mega.png";
import game9 from "../../../assets/img/games/pussy888.png";

const MobileSlotGame = () => {

	const { user } = useAuthContext();

	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.meka.games/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		setUserData(json);
	  }
	}

	useEffect(() => {
		if (user) {
			getUserData();
		}
	
	}, [user]);

  	return (
		<>

			<div className="container-fluid fluid-padding">

				<div className="row px-2 my-3">
					{/* Slots */}
					<div className="col-12 mt-3">
						<h4 className="text-gray mb-0">Mobile Slot Games</h4>
					</div>
					<div className="col-sm-6 mt-3">
						<img class="hot-games-img" src={game7} alt="game-cover"></img>
						<div className="page-sub-container text-center">
							<h5 className="text-gray mb-0">
								Username: <span className="brand-yellow">xxxx <i className="fa-solid fa-copy ml-2"></i></span>
								<br></br>
								Password: <span className="brand-yellow">aaa111 <i className="fa-solid fa-copy ml-2"></i></span>
							</h5>
							<button className="btn action-button mt-2 mx-1" onClick={(e) => {
								window.open("https://m.mega166.com/", "_blank");
							}}>
								<p className="mb-0"><strong>Download</strong></p>
							</button>
							<button disabled className="btn action-button mt-2 mx-1" onClick={(e) => {
							}}>
								<p className="mb-0"><strong>Play Now</strong></p>
							</button>
						</div>
					</div>
					<div className="col-sm-6 mt-3">
						<img class="hot-games-img" src={game9} alt="game-cover"></img>
						<div className="page-sub-container text-center">
							<h5 className="text-gray mb-0">
								Username: <span className="brand-yellow">xxxx <i className="fa-solid fa-copy ml-2"></i></span>
								<br></br>
								Password: <span className="brand-yellow">aaa111 <i className="fa-solid fa-copy ml-2"></i></span>
							</h5>
							<button disabled className="btn action-button mt-2" onClick={(e) => {
								// window.open("https://m.mega166.com/", "_blank");
							}}>
								<p className="mb-0"><strong>Coming Soon</strong></p>
							</button>
							<button disabled className="btn action-button mt-2 mx-1" onClick={(e) => {
							}}>
								<p className="mb-0"><strong>Play Now</strong></p>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
  	);

};

export default MobileSlotGame;