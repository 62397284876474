import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { useAuthContext } from '../hooks/useAuthContext';
import { useSignup } from '../hooks/useSignup';

import MainLogo from '../assets/img/logotext.png';

const Register = () => {

	const { user } = useAuthContext();
	const { referrerUsername }  = useParams();

	const [username, setUsername] = useState('');
	const [referrer, setReferrer] = useState('');
    const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
	const {signup, error, isLoading} = useSignup();

	const handleSubmit = async (e) => {
        e.preventDefault();
		if (referrer == '') {
			// console.log(referrerUsername);
			await signup(password, phone, referrerUsername);
		} else {
			// console.log(referrer);
			await signup(password, phone, referrer);
		}
    };

  	return (
		<>
		<div className="container form-page-padding">
			<div className="row py-5 my-5">
				<div className="col-12 px-2">
					<form onSubmit={handleSubmit}>
						<div className="page-container">
							<img className="form-logo mb-3" src={MainLogo}></img>
							{/* <div className="mb-3">
								<label htmlFor="username"><span className="text-gray"><strong>Username</strong></span></label>
								<input
									type="text"
									placeholder='Enter Username'
									autoComplete='off'
									name='username'
									className='form-control'
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div> */}
							{/* <div className="mb-3">
								<label htmlFor="email"><span className="text-gray"><strong>Email Address</strong></span></label>
								<input
									type="text"
									placeholder='Enter Email Address'
									autoComplete='off'
									name='email'
									className='form-control'
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div> */}
							<div className="mb-3">
								<label htmlFor="phone"><span className="text-gray"><strong>Phone Number</strong></span></label>
								<PhoneInput
									inputProps={{
										name: 'phone',
										placeholder: 'Enter Phone Number',
										required: true
									}}
									inputClass="w-100"
									country={"my"}
									enableSearch={false}
									value={phone}
									onChange={(phone) => setPhone(phone)}
								/>
							</div>
							{/* <div className="mb-3">
								<label htmlFor="referrer"><span className="text-gray"><strong>Referrer</strong></span></label>
								<input
									type="text"
									placeholder='Enter Referrer Username'
									autoComplete='off'
									name='referrer'
									className='form-control'
									defaultValue={referrerUsername}
									onChange={(e) => setReferrer(e.target.value)}
								/>
							</div> */}
							<div className="mb-3">
								<label htmlFor="password"><span className="text-gray"><strong>Password</strong></span></label>
								<input
									type="password"
									placeholder='Enter Password'
									autoComplete='off'
									name='password'
									className='form-control'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn action-button w-100 mt-2">
								<p className="mb-0"><strong>Register</strong></p>
							</button>
							{error && <div className="error mt-2">{error}</div>}
							<hr className="divider"></hr>
							<p className="text-gray mb-0">Login directly with</p>
							<button disabled className="btn action-button w-100 mt-2" onClick={(e) => {
							}}>
								<p className="mb-0"><strong>Facebook</strong></p>
							</button>
							<button disabled className="btn action-button w-100 mt-2" onClick={(e) => {
							}}>
								<p className="mb-0"><strong>Google</strong></p>
							</button>
							<button disabled className="btn action-button w-100 mt-2" onClick={(e) => {
							}}>
								<p className="mb-0"><strong>Telegram</strong></p>
							</button>
							<p className="text-gray mt-3 mb-0">Already registered?</p>
							<NavLink to="/login">
								<button className="btn secondary-btn w-100">
									<p className="mb-0"><strong>Login</strong></p>
								</button>
							</NavLink>
						</div>
					</form>
				</div>
			</div>
		</div>
		</>
  );

};

export default Register;