import React, { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import md5 from "md5";

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';


const PlayerProfile = () => {

	const { user } = useAuthContext();

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.meka.games/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		setUserData(json);
	  }
	}
  
	useEffect(() => {
		const random = "blabla";
		// const secretCode = "/6d/bADafXfgYCHmyo+aXBK5Zao=";
		const secretCode = "oOGKmN8zAycJtz9cYJQXr8ddMYQ=";
		const sn = "ld00"
		console.log(md5(random+sn+secretCode));
	  if (user) {
		getUserData();
	  }
	}, [user]);

  	return (
		<div className="container-fluid fluid-padding pb-5">
			<div className="row my-3">
				<div className="col-12 mt-3">
					<h4 className="text-gray mb-0">Player Profile</h4>

				</div>
				{userData ? (
					<div className="col-12 mt-3">
						<div className="page-container">
							<div className="row mt-3">
								<div className="col-xl-7">
									<h5 className="text-gray"><i className="fa-solid fa-user mx-2"></i>Biodata</h5>
									<div className="page-sub-container">
										<h5 className="light-yellow mb-0">Username</h5>
										<p className="referral-link text-gray mb-0">{userData.username}</p>
										<h5 className="light-yellow mt-3 mb-0">Email</h5>
										<MiddleEllipsis><span className="referral-link text-gray mb-0">{userData.email}</span></MiddleEllipsis>
										<h5 className="light-yellow mt-3 mb-0">Phone No.</h5>
										<MiddleEllipsis><span className="referral-link text-gray mb-0">+{userData.phone}</span></MiddleEllipsis>
									</div>
								</div>
								<div className="col-xl-5 mt-3 mt-xl-0">
									<h5 className="text-gray"><i className="fa-solid fa-link mx-2"></i>Refer & Earn</h5>
									<div className="page-sub-container">
										<h5 className="light-yellow mb-0">Referral Link</h5>
										<MiddleEllipsis><span className="referral-link text-gray mb-0">https://hiclub33.io/register/{userData.username}</span></MiddleEllipsis>
									</div>
									<CopyToClipboard 
										text={"https://hiclub33.io/register/" + userData.username}
                              			onCopy={() => copyReferral()}>
										<button className="btn action-button mt-2 mb-3">
											{!copied ? (
												<p className="mb-0"><strong><i className="fa-solid fa-copy mr-2"></i>Copy Link</strong></p>
											):(
												<p className="mb-0"><strong><i className="fa-solid fa-copy mr-2"></i>{copyText}</strong></p>
											)}
										</button>
									</CopyToClipboard>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-xl-7 mt-3">
									<h5 className="text-gray"><i className="fa-solid fa-circle-dollar mx-2"></i>Payment Details</h5>
									<div className="page-sub-container">
										<h5 className="light-yellow mb-0">Bank Name</h5>
										<p className="referral-link text-gray mb-0">-</p>
										<h5 className="light-yellow mt-3 mb-0">Account No.</h5>
										<p className="referral-link text-gray mb-0">-</p>
										<h5 className="light-yellow mt-3 mb-0">Account Name</h5>
										<p className="referral-link text-gray mb-0">-</p>
									</div>
								</div>
								<div className="col-xl-5 mt-3">
									<h5 className="text-gray"><i className="fa-solid fa-lock mx-2"></i>Account Security</h5>
									<div className="page-sub-container">
										<h5 className="light-yellow mb-0">Password</h5>
										<p className="referral-link text-gray mb-0">******</p>
									</div>
									<button className="btn action-button mt-2 mb-3" onClick={(e) => {
									}}>
										<p className="mb-0"><strong><i className="fa-solid fa-pen-to-square mr-2"></i>Change</strong></p>
									</button>
								</div>
							</div>
						</div>
					</div>
				):(
					<></>
				)}
			</div>
		</div>
  	);

};

export default PlayerProfile;